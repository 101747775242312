import "vite/modulepreload-polyfill";
import "@formatjs/intl-numberformat/polyfill";
import "@formatjs/intl-numberformat/locale-data/en"; // locale-data for en

import Alpine from "alpinejs";
import $ from "jquery";
import store from "store2";
import persist from "@alpinejs/persist";
import intersect from "@alpinejs/intersect";
import focus from "@alpinejs/focus";
import collapse from "@alpinejs/collapse";
import mask from "@alpinejs/mask";
import sticky from "alpinejs-sticky";
import i18next from "i18next";
import ShopifyFormat from "@shopify/i18next-shopify";
import Cookies from "js-cookie";
import { get } from "lodash-es";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import duration from "dayjs/plugin/duration";
import Swiper from "swiper/bundle";
import * as Sentry from "@sentry/browser";
import { ExtraErrorData } from "@sentry/integrations";
import counterUp from "counterup2";

import en from "../../locales/en.default.json";
import ko from "../../locales/ko.json";
import zhCN from "../../locales/zh-CN.json";
import { initDisclosureWidgets } from "../lib/a11y";
import { revive, islands } from "../lib/revive.js";
import init from "../legacy-entrypoint.js";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.tz.setDefault("America/Los_Angeles");

Sentry.init({
  dsn: "https://b5201c1d47e949b4bfbd9427aa68c599@o1208558.ingest.sentry.io/6341806",
  replaysSessionSampleRate: 0.05,
  replaysOnErrorSampleRate: 0.2,
  tracesSampleRate: 1.0,
  release: "wooltari",
  enabled: import.meta.env.DEV === "production",
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay(),
    new ExtraErrorData(),
  ],
  ignoreErrors: [
    // Random plugins/extensions
    "top.GLOBALS",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    // Facebook borked
    "fb_xd_fragment",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
  ],
  beforeSend(event, hint) {
    if (event?.request?.headers) {
      const ua = event.request.headers["User-Agent"];

      if (ua && ua.match(/Linespider|Yeti/)) {
        return null;
      }
    }

    // filter out UnhandledRejection errors that have no information
    if (
      event !== undefined &&
      event.exception !== undefined &&
      event.exception.values !== undefined &&
      event.exception.values.length == 1
    ) {
      var e = event.exception.values[0];
      if (
        e.type === "UnhandledRejection" &&
        e.value === "Non-Error promise rejection captured with value: "
      ) {
        return null;
      }
    }

    return event;
  },
});

/**
 * i18n
 */
const TRANSLATIONS = {
  en,
  ko,
  "zh-CN": zhCN,
};

i18next.use(ShopifyFormat).init({
  lng: window?.Shopify.locale || "ko",
  resources: {
    en: { translation: TRANSLATIONS.en },
    ko: { translation: TRANSLATIONS.ko },
    "zh-CN": { translation: TRANSLATIONS["zh-CN"] },
  },
});

// Legacy i18n
function i18n(properties, value) {
  return i18next.t(properties, value);
}

/**
 * Island Architecture
 */
revive(islands);

/**
 * A11Y
 */
const summaries = document.querySelectorAll('[id^="Details-"] summary');
initDisclosureWidgets(summaries);

/**
 * Alpine Plugins
 */
Alpine.plugin(persist);
Alpine.plugin(sticky);
Alpine.plugin(collapse);
Alpine.plugin(focus);
Alpine.plugin(intersect);
Alpine.plugin(mask);

/**
 * Shopify Discount Rate
 */
Alpine.magic("percentage", () => (price, compareAtPrice) => {
  const percentage = Math.floor(
    ((compareAtPrice - price) * 100) / compareAtPrice
  );

  if (Math.abs(percentage) === 0) {
    return `${Math.abs(Math.round(percentage))}%`;
  }

  return `${Math.abs(percentage)}%`;
});

/**
 * Intergrate with Shopify's currency formatting
 */
Alpine.directive("money", (el, { expression }, { evaluateLater, effect }) => {
  let getValue = evaluateLater(expression);

  effect(() => {
    getValue((value) => {
      let money = value / 100;
      let formattedPrice = Intl.NumberFormat(Shopify.locale, {
        style: "currency",
        currency: Shopify.currency.active,
        currencyDisplay: "narrowSymbol",
      }).format(money);

      el.innerText = formattedPrice;
    });
  });
});

/**
 * Judge.me Carousel
 */
document.addEventListener("DOMContentLoaded", function () {
  function fixCarousel() {
    const carouselItems = document.querySelectorAll(".jdgm-carousel-item");

    if (carouselItems && carouselItems.length > 0) {
      carouselItems.forEach((item) => {
        item.classList.add("cursor-pointer");
        const productLink = item.querySelector("a.jdgm-carousel-item__product");

        item.addEventListener("click", function (e) {
          e.preventDefault();
          window.location.href = productLink.href;
        });
      });
    }
  }

  fixCarousel();
});

/**
 * Time Sale
 */
document.addEventListener("alpine:init", () => {
  Alpine.data("productTimeSale", () => ({
    time: null,
    highlight: false,
    setTimer(eventEndTime, suffix) {
      const now = dayjs().tz();
      const diff = eventEndTime.diff(now);

      if (diff <= 3600000) {
        this.highlight = true;
      }
      if (diff <= 0) {
        this.highlight = false;
        return (this.time = "Time Out");
      }
      const duration = dayjs.duration(diff);
      const hours = duration.days();

      let timeString =
        ("0" + duration.hours()).substr(-2) +
        ":" +
        ("0" + duration.minutes()).substr(-2) +
        ":" +
        ("0" + duration.seconds()).substr(-2);
      if (hours > 0) {
        timeString = ("0" + hours).substr(-2) + ":" + timeString;
      }

      if (suffix === "null") {
        this.time = timeString;
      } else {
        this.time = timeString + " " + window.$t("product.timesale_left");
      }
    },
    init() {
      const end = this.$el.dataset.end;
      const suffix = this.$el.dataset.suffix;

      if (!end) return;

      const eventEndTime = dayjs.unix(parseInt(end)).tz();

      this.setTimer(eventEndTime, suffix);

      setInterval(() => {
        this.setTimer(eventEndTime, suffix);
      }, 1000);
    },
  }));
});

/**
 * Toast Alpine Store
 */
Alpine.store("toast", {
  notices: [],
  visible: [],
  create(notice, actionType) {
    const id = Date.now();
    this.notices.push({ message: notice, id, action: actionType });
    this.fire(id);
  },
  fire(id) {
    this.visible.push(this.notices.find((notice) => notice.id == id));
    // const timeShown = 1000 * this.visible.length;
    const timeShown = 1500;

    setTimeout(() => {
      this.remove(id);
    }, timeShown);
  },
  remove(id) {
    const notice = this.visible.find((notice) => notice.id == id);
    const visibleIndex = this.visible.indexOf(notice);
    const noticeIndex = this.notices.findIndex((item) => item.id === id);
    this.visible.splice(visibleIndex, 1);
    this.notices.splice(noticeIndex, 1);
  },
});

/**
 * Multiple Variants Selector Quick Add Button of Product Card
 */
Alpine.store("variantsModal", {
  isOpen: false,
  isInit: false,
  isSubmitting: false,
  props: null,
  selected: null,
  subtotal: 0,
  init() {
    Alpine.effect(() => {
      if (this.isOpen) {
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");
      }

      if (this.selected === null) return;
      const subtotal = this.selected.reduce((a, b) => a + b.price * b.count, 0);

      this.subtotal = subtotal;
    });
  },
  async open(handle) {
    try {
      this.isOpen = true;

      const response = await fetch(
        `${window.Shopify.routes.root}products/${handle}?view=metafields`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      const selected = data.variants.map((variant) => {
        return {
          ...variant,
          count: 0,
        };
      });

      this.isInit = true;
      this.selected = selected;
      this.props = data;
    } catch (error) {
      this.close();
    }
  },
  close() {
    this.isOpen = false;
    this.isInit = false;
    this.selected = null;
    this.isSubmitting = false;
    this.props = null;
  },
  minus(id) {
    if (this.selected) {
      const index = this.selected.findIndex((item) => item.id === id);

      if (index > -1) {
        const variant = this.selected[index];
        if (variant.count > 0) {
          variant.count--;
        }
      }
    }
  },
  plus(id) {
    if (this.selected) {
      const index = this.selected.findIndex((item) => item.id === id);

      if (index > -1) {
        const variant = this.selected[index];
        variant.count++;
      }
    }
  },
  primaryAction() {
    this.isSubmitting = true;

    if (this.selected) {
      const selectedVariants = this.selected.filter((item) => item.count > 0);

      if (selectedVariants.length === 0) {
        const message = i18next.t("cart.please_select_variants");

        this.isSubmitting = false;
        return Alpine.store("toast").create(message);
      }

      if (selectedVariants.length > 0) {
        const totalCount = selectedVariants.reduce((a, b) => a + b.count, 0);
        const min = get(this.props, "metafields.min", 0);
        const max = get(this.props, "metafields.max", 0);

        if (max > 0 && totalCount > max) {
          const message = i18next
            .t("cart.invalid_max_order_quantity_with_title")
            .replace("{{title}}", this.props.title)
            .replace("{{count}}", max);

          this.isSubmitting = false;
          return Alpine.store("toast").create(message);
        }

        if (min > 0 && totalCount < min) {
          const message = i18next
            .t("cart.invalid_min_order_quantity_with_title")
            .replace("{{title}}", this.props.title)
            .replace("{{count}}", min);

          this.isSubmitting = false;
          return Alpine.store("toast").create(message);
        }

        const items = [];
        const tags = this.props.tags;

        selectedVariants.forEach((variant) => {
          items.push({
            id: variant.id,
            quantity: variant.count,
            tags: tags,
            metafields: { stock_max: max, stock_min: min },
          });
        });

        Alpine.store("cart").addItems(
          items,
          () => this.close(),
          () => {
            this.isSubmitting = false;
          }
        );
      }
    }
  },
});

/**
 * Translation
 */
export function hasKoreanString(inputString) {
  // Regular expression to match Korean characters (Hangul syllables and Jamo characters)
  const koreanRegex =
    /[\uAC00-\uD7AF\u1100-\u11FF\u3130-\u318F\uA960-\uA97F\uAC00-\uD7A3]/;

  return koreanRegex.test(inputString);
}

document.addEventListener("DOMContentLoaded", function () {
  async function translate(text, id, targetLanguageCode = "en") {
    if (!id || !text) return;

    try {
      // text 에 울타리 또는 울타리몰 또는 울타리 몰이 있으면 Wooltari 로 변환 후 const query 로 넣어줍니다.
      // const query = text.replace(/울타리|울타리몰|울타리 몰/g, "Wooltari");
      const query = text;
      const response = await fetch(
        `https://getreviewtranslation-qmlofqf3ja-uc.a.run.app`,
        // `https://getreviewtranslation-qmlofqf3ja-uc.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id,
            mimeType: "text/html",
            contents: [query],
            sourceLanguageCode: "ko",
            targetLanguageCode: targetLanguageCode,
          }),
        }
      );

      const data = await response.json();

      return data.glossaryTranslations[0].translatedText;
    } catch (error) {
      return null;
    }
  }

  function translateCarousel({ locale }) {
    console.log("translateCarousel", locale);
    // Select .jdgm-carousel-item
    const carouselItems = document.querySelectorAll(
      ".jdgm-carousel-item__review-body"
    );
    // If body has .index class and carouselItems exists, then
    if (document.body.classList.contains("index") && carouselItems.length > 0) {
      const requests = [];

      carouselItems.forEach((item) => {
        const jdgmRev = item.closest(".jdgm-carousel-item");
        // Get data-review-id from jdgmRev
        const reviewId = jdgmRev.dataset.reviewId;

        if (!reviewId) return;

        const paragraph = item.innerText;
        let requestText = paragraph;
        // if paragraph has .. then replace .
        if (paragraph.includes("..")) {
          requestText = paragraph.replace("..", ".");
        }
        requests.push(translate(requestText, reviewId, locale));
      });

      Promise.all(requests).then((res) => {
        res.forEach((item, index) => {
          // const div = document.createElement("div");
          // div.innerHTML = item;
          if (item) {
            carouselItems[index].innerHTML = item;
          }
        });
      });
    }
  }

  function makeTranslateButton({ targetLanguageCode = "en" }) {
    console.log("makeTranslateButton", targetLanguageCode);
    const reviews = document.querySelectorAll(".jdgm-rev");

    const requests = [];

    reviews.forEach((review) => {
      const reviewId = review.dataset.reviewId;
      const body = review.querySelector(".jdgm-rev__body");
      const paragraph = body.innerHTML;
      let requestText = paragraph;
      // if paragraph has .. then replace .
      if (paragraph.includes("..")) {
        requestText = paragraph.replace("..", ".");
      }

      if (hasKoreanString(requestText) === false) return;

      requests.push(
        translate(requestText, reviewId, targetLanguageCode).then(
          (response) => {
            return { text: response, reviewId };
          }
        )
      );
    });

    Promise.all(requests).then((response) => {
      response.forEach((item) => {
        if (!item) return;

        // review is jdgm-rev-widg__body children `[data-review-id="${item.reviewId}"]`
        const widgetsBody = document.querySelector(".jdgm-rev-widg__body");
        const review = widgetsBody.querySelector(
          `[data-review-id="${item.reviewId}"]`
        );
        // const review = document.querySelector(
        //   `[data-review-id="${item.reviewId}"]`
        // );
        const body = review.querySelector(".jdgm-rev__body");
        const div = document.createElement("div");
        div.innerHTML = item.text;
        div.style.backgroundColor = "#ffedd5";
        div.style.padding = "0.5rem 1rem";
        div.style.margin = "0.5rem 0";
        div.style.borderRadius = "0.5rem";
        div.classList.add("jdgm-translate");
        div.classList.add("translated");
        div.classList.add("prose");
        div.classList.add("w-full");
        div.classList.add("max-w-full");
        if (body) {
          body.appendChild(div);
        }
      });
    });
    return;
  }

  const hasLanguage =
    window.Shopify.locale === "en" || window.Shopify.locale === "zh-CN";

  if (hasLanguage) {
    console.log("lang", window.Shopify.locale);
    const locale = window.Shopify.locale;

    makeTranslateButton({ targetLanguageCode: locale });
    translateCarousel({ locale });

    // MutationObserver 콜백 함수
    function handleMutation(mutations) {
      mutations.forEach(function (mutation) {
        // 추가된 노드를 확인하고 해당 노드에 버튼을 추가합니다.
        if (mutation.addedNodes && mutation.addedNodes.length > 0) {
          mutation.addedNodes.forEach(function (addedNode) {
            if (
              addedNode.classList &&
              addedNode.classList.contains("jdgm-paginate")
            ) {
              makeTranslateButton({ targetLanguageCode: locale });
            }
          });
        }
      });
    }

    // MutationObserver 생성 및 설정
    var observer = new MutationObserver(handleMutation);

    // 관찰할 대상 노드와 관찰할 이벤트 설정
    var targetNode = document.body; // 혹은 원하는 부모 노드로 설정
    var config = { childList: true, subtree: true };

    // MutationObserver 시작
    observer.observe(targetNode, config);
  }
});

window.$t = i18n;
window.i18next = i18next;
window.dayjs = dayjs;
window.Alpine = Alpine;
window.Cookies = Cookies;
window.Swiper = Swiper;
window.store = store;
window.$ = $;
window.jQuery = $;
window.counterUp = counterUp;

init();

Alpine.start();
